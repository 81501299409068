/// Generated by expo-google-fonts/generator
/// Do not edit by hand unless you know what you are doing
///

export { useFonts } from './useFonts';

export { default as __metadata__ } from './metadata.json';
export const PlusJakartaSans_200ExtraLight = require('./PlusJakartaSans_200ExtraLight.ttf');
export const PlusJakartaSans_300Light = require('./PlusJakartaSans_300Light.ttf');
export const PlusJakartaSans_400Regular = require('./PlusJakartaSans_400Regular.ttf');
export const PlusJakartaSans_500Medium = require('./PlusJakartaSans_500Medium.ttf');
export const PlusJakartaSans_600SemiBold = require('./PlusJakartaSans_600SemiBold.ttf');
export const PlusJakartaSans_700Bold = require('./PlusJakartaSans_700Bold.ttf');
export const PlusJakartaSans_800ExtraBold = require('./PlusJakartaSans_800ExtraBold.ttf');
export const PlusJakartaSans_200ExtraLight_Italic = require('./PlusJakartaSans_200ExtraLight_Italic.ttf');
export const PlusJakartaSans_300Light_Italic = require('./PlusJakartaSans_300Light_Italic.ttf');
export const PlusJakartaSans_400Regular_Italic = require('./PlusJakartaSans_400Regular_Italic.ttf');
export const PlusJakartaSans_500Medium_Italic = require('./PlusJakartaSans_500Medium_Italic.ttf');
export const PlusJakartaSans_600SemiBold_Italic = require('./PlusJakartaSans_600SemiBold_Italic.ttf');
export const PlusJakartaSans_700Bold_Italic = require('./PlusJakartaSans_700Bold_Italic.ttf');
export const PlusJakartaSans_800ExtraBold_Italic = require('./PlusJakartaSans_800ExtraBold_Italic.ttf');
