/// Generated by expo-google-fonts/generator
/// Do not edit by hand unless you know what you are doing
///

export { useFonts } from './useFonts';

export { default as __metadata__ } from './metadata.json';
export const Georama_100Thin = require('./Georama_100Thin.ttf');
export const Georama_200ExtraLight = require('./Georama_200ExtraLight.ttf');
export const Georama_300Light = require('./Georama_300Light.ttf');
export const Georama_400Regular = require('./Georama_400Regular.ttf');
export const Georama_500Medium = require('./Georama_500Medium.ttf');
export const Georama_600SemiBold = require('./Georama_600SemiBold.ttf');
export const Georama_700Bold = require('./Georama_700Bold.ttf');
export const Georama_800ExtraBold = require('./Georama_800ExtraBold.ttf');
export const Georama_900Black = require('./Georama_900Black.ttf');
export const Georama_100Thin_Italic = require('./Georama_100Thin_Italic.ttf');
export const Georama_200ExtraLight_Italic = require('./Georama_200ExtraLight_Italic.ttf');
export const Georama_300Light_Italic = require('./Georama_300Light_Italic.ttf');
export const Georama_400Regular_Italic = require('./Georama_400Regular_Italic.ttf');
export const Georama_500Medium_Italic = require('./Georama_500Medium_Italic.ttf');
export const Georama_600SemiBold_Italic = require('./Georama_600SemiBold_Italic.ttf');
export const Georama_700Bold_Italic = require('./Georama_700Bold_Italic.ttf');
export const Georama_800ExtraBold_Italic = require('./Georama_800ExtraBold_Italic.ttf');
export const Georama_900Black_Italic = require('./Georama_900Black_Italic.ttf');
